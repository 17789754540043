import { useNavigate } from "react-router-dom"
import { createCustomer } from "api/customers"
import CustomerForm from "./CustomerForm"

const CustomerAdd = () => {

  const navigate = useNavigate()

  const onSubmit = data => {
    createCustomer(data)
    .then(res=>{
      navigate(`/admin/customers/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <CustomerForm onSubmit={onSubmit}/>
    </div>
  )
}

export default CustomerAdd