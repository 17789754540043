import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useRef, Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'

import {
  CheckIcon,
} from '@heroicons/react/solid'
import { observer } from "mobx-react-lite"
import { getOrder } from "api/orders"
import { createInvoice } from "api/invoices"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OrderDetail = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [order, setOrder] = useState()

  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  useEffect(()=>{
    getOrder(id)
    .then(res=>{
      setOrder(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onCreateInvoice = () => {
    createInvoice({ order: order.id })
    .then(res=>{
      setOpen(false)
      navigate(`/admin/invoices/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]",res.response)
      setOpen(false)
    })
  }

  return (
    <div>
      <main className="">
          <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{order?.id}</h1>
                <p className="text-sm font-medium text-gray-500">
                  <span href="#" className={classNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                    order?.status === "10" && "bg-gray-100 text-gray-800",
                  )}>
                    {order?.status === "10" && "Pending"}
                    {order?.status === "20" && "Accepted"}
                    {order?.status === "30" && "Completed"}
                    {order?.status === "40" && "Invoiced"}
                    {order?.status === "50" && "Delivered"}
                    {order?.status === "99" && "Rejected"}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              {(order?.status === "40" || order?.status === "50") && <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={()=>navigate(`/admin/invoices/${order.invoice.id}`)}
              >
                See Invoice
              </button>}
              {(order?.status === "10" || order?.status === "20" || order?.status === "30") && <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={()=>setOpen(true)}
              >
                Create Invoice
              </button>}
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Order Information
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1 cursor-pointer" onClick={()=>navigate(`/admin/customers/${order?.customer}`)}>
                        <dt className="text-sm font-medium text-gray-500">Customer</dt>
                        <dd className="mt-1 text-sm text-gray-900 hover:text-green-500">{order?.customer_detail.email}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Total</dt>
                        <dd className="mt-1 text-sm text-gray-900">&#8369; {order?.total}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Notes</dt>
                        <dd className="mt-1 text-sm text-gray-900">{order?.notes}</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <div
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      onClick={()=>navigate("edit")}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              </section>

              <section aria-labelledby="product-discount">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Order Items
                      </h2>
                      <div
                        className="flex pt-5"
                      >
                        <div className="w-6">#</div>
                        <div className="flex-1 flex justify-between">
                          <div className="w-3/4 md:flex justify-between">
                            <div className="flex-1">Part Number</div>
                            <div className="flex-1 hidden md:block">Qty</div>
                          </div>
                          <div className="w-1/4">Price</div>
                        </div>
                      </div>
                    </div>
                    <div>
                      {order?.items.map((orderItem, idx)=>(
                        <div
                          key={orderItem.id}
                          className={classNames(
                            "flex px-4 py-5 sm:px-6",
                            idx % 2 === 0 && "bg-gray-100"
                          )}
                        >
                          <div className="w-6">{idx+1}</div>
                          <div className="flex-1 flex justify-between">
                            <div className="w-3/4 md:flex justify-between">
                              <div className="flex-1">{orderItem.item_detail.part_number}</div>
                              <div className="flex-1 flex">
                                <div className="md:hidden mr-2">Qty</div>
                                {orderItem.quantity}
                              </div>
                            </div>
                            <div className="w-1/4">&#8369; {orderItem.sell_price}</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </main>

      {/* MODAL SECTION */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Are you sure you want to proceed?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This action will create an invoice and irreversible
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={()=>onCreateInvoice()}
                  >
                    Create Invoice
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

    </div>
  )
}

export default observer(OrderDetail)