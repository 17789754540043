import { getAllUsers } from "api/users"
import { useEffect } from "react"
import { useState } from "react"
import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid'
import { useNavigate } from "react-router-dom"

const getAccessToken = () => localStorage.getItem('access')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const UserInline = ({ user }) => {

  const navigate = useNavigate()

  return (
    <li href="#" className="block hover:bg-gray-50 cursor-pointer" onClick={()=>navigate(`${user.id}`)}>
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-indigo-600 truncate">{user.name}</p>
          <div className="ml-2 flex-shrink-0 flex">
            {/* <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
              {user.is_frozen ? "noice": "not nice"}
            </p> */}
            <p className={classNames(
              "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
              user.is_frozen ? "bg-gray-100 text-gray-800" : "bg-green-100 text-green-800"
            )}>
              {user.is_frozen ? "Inactive": "Active"}
            </p>
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">
              <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {user.mobile}
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {user.role === "1" && "Admin"}
              {user.role === "2" && "Seller"}
            </p>
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div>{user.email}</div>
          </div>
        </div>
      </div>
    </li>
  )
}

const UserList = () => {

  const [users, setUsers] = useState([])
  const [next, setNext] = useState()
  const [previous, setPrevious] = useState()

  useEffect(()=>{
    getAllUsers()
    .then(res=>{
      setUsers(res.data.results)
      setNext(res.data.next)
      setPrevious(res.data.previous)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [])

  const onPrev = () => {
		previous && fetch(previous, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setUsers(res.results)
      setNext(res.next)
      setPrevious(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  const onNext = () => {
		next && fetch(next, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setUsers(res.results)
      setNext(res.next)
      setPrevious(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {users.map((user)=>(
          <UserInline key={user.id} user={user} />
        ))}
      </ul>

      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        {/* <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
            <span className="font-medium">20</span> results
          </p>
        </div> */}
        <div className="flex-1 flex justify-between sm:justify-end">
          <div
            className={classNames(
              "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white",
              previous ? "cursor-pointer hover:bg-gray-50" : "bg-gray-100",
            )}
            onClick={onPrev}
          >
            Previous
          </div>
          <div
            className={classNames(
              "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white",
              next ? "cursor-pointer hover:bg-gray-50" : "bg-gray-100",
            )}
            onClick={onNext}
          >
            Next
          </div>
        </div>
      </nav>

    </div>
  )
}

export default UserList