import { PrivateAPI } from "api"

export const getAllCustomers = (token) => {
	return PrivateAPI.get("api/customers/", { token })
}

export const getCustomer = (id) => {
	return PrivateAPI.get(`api/customers/${id}/`)
}

export const createCustomer = (data) => {
  return PrivateAPI.post(`api/customers/`, data)
}

export const updateCustomer = (id, data) => {
  return PrivateAPI.put(`api/customers/${id}/`, data)
}

export const createSpecialPrice = (customer_id, data) => {
  return PrivateAPI.post(`api/customers/${customer_id}/special-prices/`, data)
}

export const updateSpecialPrice = (customer_id, special_price_id, data) => {
  return PrivateAPI.put(`api/customers/${customer_id}/special-prices/${special_price_id}/`, data)
}

export const getAllCustomersForOrderForm = () => {
  return PrivateAPI.get(`api/customers/all`)
}

export const getCustomerOrders = id => {
  return PrivateAPI.get(`api/customers/${id}/orders/`)
}