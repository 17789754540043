import { createOrder } from "api/orders"
import { observer } from "mobx-react-lite"
import { useAuthContext } from "store/auth"
import OrderForm from "./OrderForm"

const OrderAdd = () => {

  const authStore = useAuthContext()

  const onSubmit = data => {
    console.log(data)
    data.customer = authStore.user.customer.id
    createOrder(data)
    .then(res=>{
      console.log(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <OrderForm onSubmit={onSubmit}/>
    </div>
  )
}

export default observer(OrderAdd)