import { createSpecialPrice, updateSpecialPrice } from "api/customers"
import { useForm } from "react-hook-form"

const CustomerSpecialPriceForm = ({ items, specialPrice, customer }) => {

  const { register, handleSubmit } = useForm({
    defaultValues: specialPrice
  })

  const onSubmit = handleSubmit((data)=>{
    if (data.id) {
      updateSpecialPrice(customer.id, data.id, data)
      .then(res=>{
        console.log(res)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
      })
    } else {
      data.customer = customer.id
      createSpecialPrice(customer.id, data)
      .then(res=>{
        console.log(res)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
      })
    }
  })

  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-6 mb-3">
        <div className="col-span-4 sm:col-span-2">
          <select
            {...register('item', { required: true })}
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            defaultValue=""
            onChange={(e)=>{
              
              // const tempItem = items.find((itemx)=> itemx.id === parseInt(e.target.value) )
              // setValue(`items[${idx}].sell_price`, tempItem.sell_price)

            }}
          >
            <option disabled value="" hidden className="text-gray-300">Select an item</option>
            {items.map((item)=>(
              <option key={item.id} value={item.id}>{item.name}</option>
            ))}
          </select>
        </div>
        <div className="col-span-2">
          <label className="block sm:hidden text-sm font-medium text-gray-700">
            Sell Price
          </label>
          <input
            {...register('sell_price', { required: true })}
            type="number"
            min={0}
            step={'0.01'}
            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
            placeholder="Price"
          />
        </div>
        <div className="flex justify-end">
          
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Delete
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default CustomerSpecialPriceForm