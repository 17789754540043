import {
  Outlet,
} from 'react-router-dom';

import AppSideNav from './components/AppSideNav';
import Test from './components/Modalers';

const App = () => {

  return (
    <div className='flex h-full'>
      <AppSideNav />
      <div className='w-full'>
        <Test />
        <Outlet />
      </div>
    </div>
  )
}

export default App