import { getInvoice, updateInvoice } from "api/invoices"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useAuthContext } from "store/auth"
import InvoiceForm from "./InvoiceForm"

const OrderEdit = () => {

  const { id } = useParams()
  const navigate = useNavigate()
  const [order, setOrder] = useState()

  useEffect(()=>{
    getInvoice(id)
    .then(res=>{
      setOrder(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onSubmit = data => {
    updateInvoice(id, data)
    .then(res=>{
      navigate(`/admin/invoices/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <InvoiceForm onSubmit={onSubmit} initialValues={order}/>
    </div>
  )
}

export default observer(OrderEdit)