import { createOrder } from "api/orders"
import { observer } from "mobx-react-lite"
import { useNavigate } from "react-router-dom"
import OrderForm from "./OrderForm"

const OrderAdd = () => {

  const navigate = useNavigate()

  const onSubmit = data => {
    createOrder(data)
    .then(res=>{
      navigate(`/admin/orders/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <OrderForm onSubmit={onSubmit}/>
    </div>
  )
}

export default observer(OrderAdd)