import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { Switch } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CustomerForm = ({ onSubmit, initialValues }) => {

  const { register, handleSubmit, reset } = useForm({
    defaultValues: initialValues
  })

  const [isActive, setIsActive] = useState(true)

  const onFormSubmit = handleSubmit((data)=>{
    data.is_active = isActive
    onSubmit(data)
  })

  useEffect(()=>{
    reset(initialValues)
    if (initialValues) {
      setIsActive(initialValues.is_active)
    }
  }, [initialValues, reset])

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={onFormSubmit}>
      <div className="space-y-8 divide-y divide-gray-200">

        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Item Information</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill up as much detail as possible.</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Type
              </label>
              <div className="mt-1">
              <select
                {...register('type')}
                id="type"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue=""
              >
                <option value={"1"}>Stock</option>
                <option value={"2"}>Labor</option>
                <option value={"3"}>Misc</option>
              </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Part Number
              </label>
              <div className="mt-1">
                <input
                  {...register('part_number', { required: true })}
                  type="text"
                  // name="first-name"
                  // id="first-name"
                  // autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Name
              </label>
              <div className="mt-1">
                <input
                  {...register('name')}
                  type="text"
                  // name="first-name"
                  // id="first-name"
                  // autoComplete="given-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Is Active?
              </label>
              <div className="mt-1">
                <input
                  {...register('is_active')}
                  type="checkbox"
                  defaultValue={false}
                  className="hidden shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
                <Switch
                  checked={isActive}
                  onChange={setIsActive}
                  className={classNames(
                    isActive ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      isActive ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                    )}
                  />
                </Switch>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Sell Price
              </label>
              <div className="mt-1">
                <input
                  {...register('sell_price', {required: true})}
                  type="number"
                  step="0.01"
                  min={0}
                  id="first-name"
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                List Price
              </label>
              <div className="mt-1">
                <input
                  {...register('list_price', {required: true})}
                  // id="email"
                  // name="email"
                  type="number"
                  step="0.01"
                  min={0}
                  // autoComplete="email"
                
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <textarea
                  {...register('description', {required: true})}
                  id="about"
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                  // ref={...register('notes')}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500">Write a few sentences about the user.</p>
            </div>

          </div>
        </div>

        <div className='pt-8'>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Stock Count</h3>
            <p className="mt-1 text-sm text-gray-500">
              This information will only be for company use only.
            </p>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                Stock
              </label>
              <div className="mt-1">
                <input
                  {...register('stock', {required: true})}
                  type="number"              
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default CustomerForm