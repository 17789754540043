import { PrivateAPI } from "api"

export const getAllInvoices = () => {
	return PrivateAPI.get("api/invoices/")
}

export const getInvoice = (id) => {
	return PrivateAPI.get(`api/invoices/${id}/`)
}

export const createInvoice = (data) => {
  return PrivateAPI.post(`api/invoices/`, data)
}

export const updateInvoice = (id, data) => {
  return PrivateAPI.put(`api/invoices/${id}/`, data)
}