import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import PayoutForm from "./PayoutForm"
import { getPayout, updatePayout } from "api/payouts"

const PayoutEdit = () => {

  const navigate = useNavigate()

  const { id } = useParams()
  const [payout, setPayout] = useState()

  useEffect(()=>{
    getPayout(id)
    .then(res=>{
      setPayout(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onSubmit = data => {
    updatePayout(id, data)
    .then(res=>{
      navigate(`/admin/payouts/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <PayoutForm onSubmit={onSubmit} initialValues={payout}/>
    </div>
  )
}

export default PayoutEdit