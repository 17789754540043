import { observer } from "mobx-react-lite"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "store/auth"
import { useForm } from "react-hook-form"
import { login } from "api/auth"

const Login = () => {

  const navigate = useNavigate()
  const authStore = useAuthContext()

  const { register, handleSubmit } = useForm()

  const onSubmit = handleSubmit((data)=>{
    login(data)
    .then(res=>{
      authStore.logUserIn(res.data.user)
      localStorage.setItem('access', res.data.access)
    })
    .catch(res=>{
      console.log(res.response)
    })
  })

  useEffect(()=>{
    if (authStore.isLogged && authStore.user?.role === "1") {
      navigate("/admin")
    } else if (authStore.isLogged && authStore.user?.role === "2") {
      navigate("/app")
    } 
  }, [authStore.isLogged, navigate, authStore.user?.role])

  return (
    <div className="m-2 w-full flex justify-center">
      <div className="m-auto bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <h1 className="cursor-pointer mb-3 font-bold" onClick={()=>navigate(`/`)}>Leichengdabs</h1>

        <form onSubmit={onSubmit}>

          <input
            {...register("username", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Email"
          />

          <input
            {...register("password", { required: true })}
            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            placeholder="Password"
            type="password"
          />

          <button type="submit" className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Login</button>

        </form>

      </div>
    </div>
  )
}

export default observer(Login)