import { UserCircleIcon } from "@heroicons/react/solid"
import { PhoneIcon, DeviceMobileIcon } from "@heroicons/react/outline"

import Nav from "components/nav"

import { sellers } from "consts"

const Sellers = () => {
  return (
    <div className="overflow-hidden bg-sellers-bg bg-center bg-no-repeat bg-cover">
      <Nav />

      <div className="max-height-screen h-screen w-full">
        <div className="max-w-screen-xl m-auto w-full text-center">
          <h1 className="text-3xl font-medium text-gray-800 sm:text-5xl">Sellers</h1>
          <p className="p-2 text-2xl">Feel free to contact any of Super Tofu's sellers!</p>
        </div>

        <ul
            className="mx-auto grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-4 md:gap-x-6 lg:max-w-5xl lg:gap-x-8 lg:gap-y-12 xl:grid-cols-6 my-5"
          >
            {sellers.map((seller) => (
              <li key={seller.name}>
                <div className="space-y-4 text-center">
                  <UserCircleIcon className="mx-auto h-20 w-20 rounded-full lg:w-24 lg:h-24"/>
                  <div className="space-y-2">
                    <div className="text-xs font-medium lg:text-sm">
                      <h3>{seller.name}</h3>
                      {seller.phone && <p className="text-indigo-600 flex justify-center items-center gap-x-2">
                        <PhoneIcon className="h-4 w-4" />
                        {seller.phone}
                      </p>}
                      {seller.mobile && <p className="text-indigo-600 flex justify-center items-center gap-x-2">
                        <DeviceMobileIcon className="h-4 w-4" />
                        {seller.mobile}
                      </p>}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

      </div> 

      

    </div>
  );
}

export default Sellers
