import { Outlet } from "react-router-dom"

import UserNav from "./components/UserNav"

const AdminUser = () => {

  // const navigate = useNavigate()

  return (
    <main className="flex-1">
      <div className="py-6">
        <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Users</h1>
          <UserNav />
        </div>

        <div className="max-w-8xl mx-auto px-4 sm:px-6 md:px-8">
          <div className="py-4">
            <div className="">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AdminUser