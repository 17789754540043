import { useNavigate } from "react-router-dom"
import { StarIcon } from "@heroicons/react/solid"

import Nav from "components/nav"

import Follow from "./components/Follow"
import { products, reviews } from "consts"

import eventImg from "img/events2.jpg"
import stImg from "img/tofu.jpg"

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon } from '@heroicons/react/outline'

const transferFeatures = [
  {
    id: 1,
    name: 'Weddings',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: GlobeAltIcon,
  },
  {
    id: 2,
    name: 'Birthdays',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: ScaleIcon,
  },
  {
    id: 3,
    name: 'Catering',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: LightningBoltIcon,
  },
]

const communicationFeatures = [
  {
    id: 1,
    name: 'Tasty Tofus',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: AnnotationIcon,
  },
  {
    id: 2,
    name: 'Sellers',
    description:
      'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
    icon: MailIcon,
  },
]

const title = "Leichengdabs"

const Home = () => {

  const navigate = useNavigate()

  const onRedirect = () => {
    navigate("/sellers")
  }

  return (
    <div>
 
      <div className="bg-meeting bg-center bg-no-repeat bg-cover flex flex-col">
        <Nav />
        <div className="min-h-75vh w-full flex items-center justify-center flex-col gap-y-5 md:min-h-80vh">
            <h1 className="text-2xl font-medium text-gray-800 md:text-6xl">
              {[...title].map((letter, idx)=>(
                <span key={idx} className="hover:text-red-300 cursor-default">{letter}</span>
              ))}
            </h1>
            <div className="flex flex-col gap-x-10 md:flex-row gap-y-5">
              <button
                className="w-36 inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >Events</button>
              <button
                className="w-36 inline-flex items-center justify-center px-6 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                // onClick={onRedirect}
              >Supertofu</button>
            </div>
        </div>
      </div>

      <div className="max-w-screen-xl m-auto py-8 md:py-24 px-4">

        <div className="relative">
          <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            What we offer
          </h2>
          <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
            Be amazed at our greatness and beauty!
          </p>
        </div>

        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="relative">
            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
              Event management services
            </h3>
            <p className="mt-3 text-lg text-gray-500">
              With our current struggle during this pandemic, we will guarantee you a safe handling of your events. For over thousands of years, we have delivered the best service to people here in La Union. Weddings, birthdays, apocalypse. You name it all.
            </p>

            <dl className="mt-10 space-y-10">
              {transferFeatures.map((item) => (
                <div key={item.id} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                      <item.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                </div>
              ))}
            </dl>
          </div>

          <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
            <div className="text-center box-border px-3">
              <div className="rounded-md overflow-hidden inline-block shadow-xl ring-black ring-opacity-5">
                <img
                  className="relative mx-auto"
                  width={490}
                  // src="https://tailwindui.com/img/features/feature-example-1.png"
                  src={eventImg}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>        
      </div>

      <div className="relative mt-12 sm:mt-16 lg:mt-24 px-4 pb-24">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
            <div className="lg:col-start-2">
              <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Super Tofu</h3>
              <p className="mt-3 text-lg text-gray-500">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus,
                cum deleniti sunt in labore nihil quod quibusdam expedita nemo.
              </p>

              <dl className="mt-10 space-y-10">
                {communicationFeatures.map((item) => (
                  <div key={item.id} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">
                        <item.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                  </div>
                ))}
              </dl>
            </div>

            <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
              <div className="text-center box-border px-3">
                <div className="rounded-md overflow-hidden inline-block shadow-xl ring-black ring-opacity-5">
                  <img
                    className="relative mx-auto"
                    width={490}
                    // src="https://tailwindui.com/img/features/feature-example-1.png"
                    src={stImg}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

      <Follow />

      <div style={{background: "#fcfcfc"}}>
        <div className="py-12 px-4 max-w-screen-xl m-auto md:py-24">
          <header className="text-gray-800 text-2xl font-semibold mb-5">Reviews</header>
          <div className="">
            {reviews.map((review) => (
              <div key={review.id} className="group relative cursor-pointer pb-8 mb-8 border border-t-0 border-l-0 border-r-0">
                <div className="font-medium text-xl">
                  {review.name}
                </div>
                <div className="text-gray-500 mb-2">
                  {review.date}
                </div>

                <div className="flex mb-2 text-yellow-400">
                  {[...Array(review.rating).keys()].map((item) => (
                    <StarIcon className="h-4 w-4" />
                  ))}
                </div>

                <p>
                  {review.review}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>

    </div>
  );
}

export default Home
