import { PrivateAPI } from "api"

export const getAllUsers = (token) => {
	return PrivateAPI.get("api/users/", { token })
}

export const getUser = (id) => {
	return PrivateAPI.get(`api/users/${id}/`)
}

export const createUser = (data) => {
  return PrivateAPI.post(`api/users/`, data)
}

export const updateUser = (id, data) => {
  return PrivateAPI.put(`api/users/${id}/`, data)
}

export const getUserList = () => {
  return PrivateAPI.get(`api/users/list/`)
}

export const getUserOrders = id => {
  return PrivateAPI.get(`api/users/${id}/orders/`)
}

export const getUserInvoices = id => {
  return PrivateAPI.get(`api/users/${id}/invoices/`)
}

export const getUserPayouts = id => {
  return PrivateAPI.get(`api/users/${id}/payouts/`)
}