import { useNavigate } from "react-router-dom"

import Dropdown from "./components/Dropdown"

const Nav = () => {

  const navigate = useNavigate()

  const onRedirect = () => {
    navigate("/")
  }

  return <div className="flex justify-between p-4">
    <div className="text-xl font-semibold" onClick={onRedirect}>leichengdabs</div>
    <Dropdown />
  </div>
}

export default Nav