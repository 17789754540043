import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import { useAuthContext } from "store/auth"

const AdminRoute = ({ children }) => {

  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()
  const authStore = useAuthContext()

  useEffect(()=>{
    if (!(authStore.isLogged && authStore.user?.role === "1") && !loading) {
      navigate("/login")
    }
    setLoading(false)
  }, [authStore.isLogged, authStore.user?.role, navigate, loading])

  if (loading) {
    return (
      <div>Loading...</div>
    )
  }

  return <>{children}</>
}

export default observer(AdminRoute)