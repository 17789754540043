// import { useState } from 'react'
// import { Dialog, Transition } from '@headlessui/react'

import {
  // CalendarIcon,
  // ChartBarIcon,
  // FolderIcon,
  // HomeIcon,
  // InboxIcon,
  // MenuIcon,
  // UsersIcon,
  // XIcon,
} from '@heroicons/react/outline'

import {
  // Link,
  Outlet,
  // useNavigate,
  // useParams,
  // useLocation,
} from 'react-router-dom';

import AdminSideNav from './components/AdminSideNav';

import Test from "./components/Modalers2"

const Admin = () => {
  // const [sidebarOpen, setSidebarOpen] = useState(false)
  // const navigate = useNavigate()
  // const location = useLocation()
  // const [navigation, setNavigation] = useState([
  //   { name: 'Dashboard', path: '', icon: HomeIcon, current: true },
  //   { name: 'Team', path: 'team', icon: UsersIcon, current: false },
  //   { name: 'Products', path: 'products', icon: FolderIcon, current: false },
  //   // { name: 'Calendar', path: '#', icon: CalendarIcon, current: false },
  //   // { name: 'Documents', path: '#', icon: InboxIcon, current: false },
  //   // { name: 'Reports', path: '#', icon: ChartBarIcon, current: false },
  // ])

  // const view = location.pathname.split("/", 3)[2] ? location.pathname.split("/", 3)[2] : ""

  return (
    <div className='flex h-full'>
      <AdminSideNav />
      <div className='w-full'>
        <Test />
        <Outlet />
      </div>
    </div>
  )
}

export default Admin