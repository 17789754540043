import {
  CalendarIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  LogoutIcon,
} from '@heroicons/react/outline'
import { useLocation, useNavigate } from 'react-router-dom'
// import { useState } from 'react'
import { useAuthContext } from 'store/auth'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const navigation = [
  { name: 'Dashboard', path: '', icon: HomeIcon},
  { name: 'Customers', path: 'customers', icon: UsersIcon},
  { name: 'Items', path: 'items', icon: FolderIcon},
  { name: 'Orders', path: 'orders', icon: FolderIcon},
  { name: 'Invoices', path: 'invoices', icon: FolderIcon},
  { name: 'Payouts', path: 'payouts', icon: FolderIcon},
  { name: 'Users', path: 'users', icon: CalendarIcon},
  // { name: 'Documents', path: '#', icon: InboxIcon},
  // { name: 'Reports', path: '#', icon: ChartBarIcon},
]

const AdminSideNav = () => {

  const authStore = useAuthContext()

  const navigate = useNavigate()
  const location = useLocation()
  const view = location.pathname.split("/", 3)[2] ? location.pathname.split("/", 3)[2] : ""

  return (
    <div className="border-r border-gray-200 p-2 hidden md:block w-56">
      <div className="flex flex-col h-full">
        <div className="flex-1">
          <div className="font-semibold text-center text-xl border-b border-gray-200 my-2 py-2 cursor-pointer" onClick={()=>navigate("/")}>SUPERTOFU</div>
            {navigation.map((item) => (
              <div
                key={item.name}
                onClick={()=>{
                  navigate(item.path)
                }}
                className={classNames(
                  item.path === view
                    ? 'bg-gray-100 text-gray-900'
                    : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                  'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                  'cursor-pointer'
                )}
              >
                <item.icon
                  className={classNames(
                    item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                    'mr-4 flex-shrink-0 h-6 w-6'
                  )}
                  aria-hidden="true"
                />
                {item.name}
              </div>
            ))}
        </div>
        <div className='cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md border-t border-gray-200' onClick={()=>{
          authStore.logUserOut()
          navigate("/")
        }}>
          <LogoutIcon className='text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'/>
          Logout
        </div>
      </div>
    </div>
  )
}

export default AdminSideNav