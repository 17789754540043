import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuIcon,
  UsersIcon,
  XIcon,
  LogoutIcon,
  CurrencyDollarIcon,
} from '@heroicons/react/outline'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuthContext } from 'store/auth'

const navigation = [
  // { name: 'Dashboard', path: '', icon: HomeIcon},
  { name: 'Orders', path: 'orders', icon: HomeIcon},
  { name: 'Invoice', path: 'invoices', icon: UsersIcon},
  { name: 'Payouts', path: 'payouts', icon: UsersIcon},
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Test = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const view = location.pathname.split("/", 3)[2] ? location.pathname.split("/", 3)[2] : ""
  const authStore = useAuthContext()
  return (
    <div className='flex flex-col'>
      <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  <div className="font-semibold text-center text-xl border-b border-gray-200 my-2 py-2 cursor-pointer" onClick={()=>navigate("/")}>SUPERTOFU</div>
                  <nav className="mt-5 px-2 space-y-1">
                    <div className='cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md' onClick={()=>{
                    }}>
                      <CurrencyDollarIcon className='text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'/>
                      {authStore?.user.payout}
                    </div>
                    {navigation.map((item) => (
                      <div
                        key={item.name}
                        onClick={()=>{
                          navigate(item.path)
                          setSidebarOpen(false)
                        }}
                        className={classNames(
                          item.path === view
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                          'cursor-pointer'
                        )}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                            'mr-4 flex-shrink-0 h-6 w-6'
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </div>
                    ))}
                  </nav>
                </div>
                <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
                  <div className='cursor-pointer text-gray-600 hover:bg-gray-50 hover:text-gray-900 group flex items-center px-2 py-2 text-base font-medium rounded-md border-t border-gray-200' onClick={()=>{
                    authStore.logUserOut()
                    navigate("/")
                  }}>
                    <LogoutIcon className='text-gray-400 group-hover:text-gray-500 mr-4 flex-shrink-0 h-6 w-6'/>
                    Logout
                  </div>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
          </Dialog>
        </Transition.Root>
    </div>
  )
}

export default Test