import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import CustomerForm from "./CustomerForm"
import { getCustomer, updateCustomer } from "api/customers"
import { v4 as uuidv4 } from 'uuid';
import { observer } from "mobx-react-lite";
import { useAuthContext } from "store/auth";
import { getItemsByStatus } from "api/items";
import CustomerSpecialPriceForm from "./CustomerSpecialPriceForm";

const CustomerEdit = () => {

  const navigate = useNavigate()
  const authStore = useAuthContext()

  const { id } = useParams()
  const [customer, setCustomer] = useState()
  const [specialPrices, setSpecialPrices] = useState([])
  const [items, setItems] = useState([])

  useEffect(()=>{
    getCustomer(id)
    .then(res=>{
      setCustomer(res.data)
      setSpecialPrices(res.data.special_prices.map(sp=>({
        ...sp,
        uuid: uuidv4()
      })))
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  useEffect(()=>{
    if (authStore.isLogged) {
      getItemsByStatus({ is_active: true })
      .then(res=>{
        setItems(res.data)
        // setLoading(false)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
        // setLoading(false)
      })
    }

  }, [authStore.isLogged])

  const onSubmit = data => {
    updateCustomer(id, data)
    .then(res=>{
      navigate(`/admin/customers/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  const onItemAdd = (e) => {
    e.preventDefault()
    setSpecialPrices(prevState=>[
      ...prevState,
      {
        uuid: uuidv4()
      }
    ])
  }

  return (
    <div>
      <CustomerForm onSubmit={onSubmit} initialValues={customer}/>

      <h3 className="text-lg leading-6 font-medium text-gray-900 mb-3">Special Pricing</h3>

      {specialPrices?.map(sp=>{
        return <CustomerSpecialPriceForm key={sp.uuid} specialPrice={sp} items={items} customer={customer} />
      })}

      <button
        type="button"
        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 mt-3"
       onClick={onItemAdd}>add item</button>

    </div>
  )
}

export default observer(CustomerEdit)