import { getUser, updateUser } from "api/users"
import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import UserForm from "./UserForm"

const UserEdit = () => {

  const navigate = useNavigate()

  const { id } = useParams()
  const [user, setUser] = useState()

  useEffect(()=>{
    getUser(id)
    .then(res=>{
      setUser(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onSubmit = data => {
    updateUser(id, data)
    .then(res=>{
      navigate(`/admin/users/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <UserForm onSubmit={onSubmit} initialValues={user}/>
    </div>
  )
}

export default UserEdit