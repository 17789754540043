import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useRef, Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'

import {
  CheckIcon,
  ThumbUpIcon,
  UserIcon,
} from '@heroicons/react/solid'
import { observer } from "mobx-react-lite"
import { getPayout } from "api/payouts"

const eventTypes = {
  applied: { icon: UserIcon, bgColorClass: 'bg-gray-400' },
  advanced: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
  completed: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
}

const timeline = [
  {
    id: 1,
    type: eventTypes.applied,
    content: 'Applied to',
    target: 'Front End Developer',
    date: 'Sep 20',
    datetime: '2020-09-20',
  },
  {
    id: 2,
    type: eventTypes.advanced,
    content: 'Advanced to phone screening by',
    target: 'Bethany Blake',
    date: 'Sep 22',
    datetime: '2020-09-22',
  },
  {
    id: 3,
    type: eventTypes.completed,
    content: 'Completed phone screening with',
    target: 'Martha Gardner',
    date: 'Sep 28',
    datetime: '2020-09-28',
  },
  {
    id: 4,
    type: eventTypes.advanced,
    content: 'Advanced to interview by',
    target: 'Bethany Blake',
    date: 'Sep 30',
    datetime: '2020-09-30',
  },
  {
    id: 5,
    type: eventTypes.completed,
    content: 'Completed interview with',
    target: 'Katherine Snyder',
    date: 'Oct 4',
    datetime: '2020-10-04',
  },
]


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PayoutDetail = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [payout, setPayout] = useState()

  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  // const authStore = useAuthContext()

  useEffect(()=>{
    getPayout(id)
    .then(res=>{
      setPayout(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  return (
    <div>
      <main className="">
          <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">Payout ID: {payout?.id}</h1>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Payout Information
                    </h2>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">User</dt>
                        <dd className="mt-1 text-sm text-gray-900">{payout?.user}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Amount</dt>
                        <dd className="mt-1 text-sm text-gray-900">{payout?.amount}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Method</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {payout?.method === "1" && "GCash"}
                          {payout?.method === "2" && "BPI"}
                        </dd>
                      </div>
                      {/* <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Is Active?</dt>
                        <dd className="mt-1 text-sm text-gray-900">{payout?.is_active}</dd>
                      </div> */}
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Reference Number</dt>
                        <dd className="mt-1 text-sm text-gray-900">{payout?.reference_number}</dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <div
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      onClick={()=>navigate("edit")}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              </section>
            </div>

            
          </div>
        </main>
    </div>
  )
}

export default observer(PayoutDetail)