import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useRef, Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'

import dayjs from "dayjs"

import {
  // ArrowNarrowLeftIcon,
  CheckIcon,
  // HomeIcon,
  // PaperClipIcon,
  // QuestionMarkCircleIcon,
  // SearchIcon,
  ThumbUpIcon,
  UserIcon,
} from '@heroicons/react/solid'
import { getCustomer, getCustomerOrders } from "api/customers"
// import { useAuthContext } from "store/auth"
import { observer } from "mobx-react-lite"

const getAccessToken = () => localStorage.getItem('access')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CustomerDetail = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [customer, setCustomer] = useState()

  const [orders, setOrders] = useState([])
  const [nextOrders, setNextOrders] = useState()
  const [previousOrders, setPreviousOrders] = useState()

  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  // const authStore = useAuthContext()

  useEffect(()=>{
    getCustomer(id)
    .then(res=>{
      setCustomer(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
    getCustomerOrders(id)
    .then(res=>{
      setOrders(res.data.results)
      setNextOrders(res.data.next)
      setPreviousOrders(res.data.previous)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onPrev = () => {
		previousOrders && fetch(previousOrders, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setOrders(res.results)
      setNextOrders(res.next)
      setPreviousOrders(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  const onNext = () => {
		nextOrders && fetch(nextOrders, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setOrders(res.results)
      setNextOrders(res.next)
      setPreviousOrders(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  return (
    <div>

      <main className="">
          {/* Page header */}
          <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{customer?.name}</h1>
                <p className="text-sm font-medium text-gray-500">
                  <span href="#" className={classNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                    customer?.is_frozen ? "bg-gray-100 text-gray-800" : "bg-green-100 text-green-800"
                  )}>
                    {customer?.is_frozen ? "Inactive": "Active"}
                  </span>
                  {' '}
                  <span href="#" className={classNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                    "bg-blue-100 text-blue-800"
                  )}>
                    {customer?.role === "1" && "Admin"}
                    {customer?.role === "2" && "Seller"}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              {customer?.user && <button
                type="button"
                className="inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                onClick={()=>navigate(`/admin/users/${customer?.user}`)}
              >
                See user
              </button>}
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Customer Information
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">User</dt>
                        <dd className="mt-1 text-sm text-gray-900 cursor-pointer hover:text-green-500" onClick={()=>navigate(`/admin/users/${customer?.user_detail?.id}`)}>{customer?.user_detail?.email}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Referred by</dt>
                        <dd className="mt-1 text-sm text-gray-900 cursor-pointer hover:text-green-500" onClick={()=>navigate(`/admin/users/${customer?.referred_by_detail?.id}`)}>{customer?.referred_by_detail?.email}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Name</dt>
                        <dd className="mt-1 text-sm text-gray-900">{customer?.name}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Email address</dt>
                        <dd className="mt-1 text-sm text-gray-900">{customer?.email}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Mobile</dt>
                        <dd className="mt-1 text-sm text-gray-900">{customer?.mobile}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Phone</dt>
                        <dd className="mt-1 text-sm text-gray-900">{customer?.phone}</dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Notes</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {customer?.notes}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <div
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      onClick={()=>navigate("edit")}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              </section>

              <section aria-labelledby="product-discount">
                <div className="bg-white shadow sm:rounded-lg sm:overflow-hidden">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Special Prices
                      </h2>
                    </div>
                    <div className="px-4 py-5 sm:px-6">
                      {customer?.special_prices.map(sp=>(
                        <div key={sp.id} className="flex gap-x-3">
                          <div className="text-base font-medium text-gray-500">{sp.item_detail.part_number}</div>
                          <div className="text-gray-900">&#8369; {sp.sell_price}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
              <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
                  Orders
                </h2>

                {/* Activity Feed */}
                <div className="mt-6 flow-root">
                  <ul className="-mb-8">
                    {orders?.map((order, idx) => {
                      return (
                        <li key={order.id} onClick={()=>navigate(`/admin/orders/${order.id}`)}>
                          <div className="relative pb-8">
                            {idx !== orders.length - 1 ? (
                              <span
                                className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                                aria-hidden="true"
                              />
                            ) : null}
                            <div className="relative flex space-x-3">
                              <div>
                                <span
                                  className={classNames(
                                    // item.type.bgColorClass,
                                    order.status === "10" && "bg-gray-400",
                                    order.status === "20" && "bg-yellow-400",
                                    order.status === "30" && "bg-blue-400",
                                    order.status === "40" && "bg-indigo-400",
                                    order.status === "50" && "bg-green-400",
                                    order.status === "99" && "bg-red-400",
                                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                  )}
                                >
                                  {/* <item.type.icon className="w-5 h-5 text-white" aria-hidden="true" /> */}
                                  {order.status === "10" && <div className="text-white text-sm font-medium">Pen</div>}
                                  {order.status === "20" && <div className="text-white text-sm font-medium">Acc</div>}
                                  {order.status === "30" && <div className="text-white text-sm font-medium">Com</div>}
                                  {order.status === "40" && <div className="text-white text-sm font-medium">Inv</div>}
                                  {order.status === "50" && <div className="text-white text-sm font-medium">Del</div>}
                                  {order.status === "99" && <div className="text-white text-sm font-medium">Rej</div>}
                                </span>
                              </div>
                              <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                <div className="text-gray-700 font-medium cursor-pointer hover:font-bold hover:text-green-600">
                                  <span className="mr-3">Order {order.id}</span>
                                  &#8369; {order.total}
                                </div>
                                <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                  {dayjs(order.created).format("MMM DD")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                <div className="mt-6 flex justify-between">
                  <button
                    type="button"
                    className={classNames(
                      "inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none  focus:ring-offset-2 focus:ring-blue-500",
                      previousOrders ? "hover:bg-blue-700 bg-blue-600 focus:ring-2" : "bg-gray-200 text-gray-500",
                    )}
                    onClick={onPrev}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      "inline-flex items-center justify-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white  focus:outline-none  focus:ring-offset-2 focus:ring-blue-500",
                      nextOrders ? "hover:bg-blue-700 bg-blue-600 focus:ring-2" : "bg-gray-200 text-gray-500",
                    )}
                    onClick={onNext}
                  >
                    Next
                  </button>
                </div>
              </div>
            </section>
          </div>
        </main>

      {/* MODAL SECTION */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100">
                    <CheckIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                      Are you sure you want to proceed?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        This action will create a new customer based on this user ({customer?.name})
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
            
                  >
                    Create customer
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

    </div>
  )
}

export default observer(CustomerDetail)