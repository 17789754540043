import { PrivateAPI } from "api"

export const getAllPayouts = () => {
	return PrivateAPI.get("api/payouts/")
}

export const getPayout = (id) => {
	return PrivateAPI.get(`api/payouts/${id}/`)
}

export const createPayout = (data) => {
  return PrivateAPI.post(`api/payouts/`, data)
}

export const updatePayout = (id, data) => {
  return PrivateAPI.put(`api/payouts/${id}/`, data)
}