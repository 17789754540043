import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useState, useRef, Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'

import {
  CheckIcon,
} from '@heroicons/react/solid'
import { observer } from "mobx-react-lite"
import { getOrder } from "api/orders"
import { createInvoice, getInvoice } from "api/invoices"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const InvoiceDetail = () => {

  const navigate = useNavigate()
  const { id } = useParams()
  const [invoice, setInvoice] = useState()

  const [open, setOpen] = useState(false)
  const cancelButtonRef = useRef(null)

  const total = invoice ? invoice.order_detail.items.reduce((a, b) => {return a + b.quantity * b.sell_price}, 0) : 0

  useEffect(()=>{
    getInvoice(id)
    .then(res=>{
      setInvoice(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  return (
    <div>
      <main className="">
          <div className="max-w-3xl mx-auto md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
            <div className="flex items-center space-x-5">
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{invoice?.id}</h1>
                <p className="text-sm font-medium text-gray-500">
                  <span href="#" className={classNames(
                    "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
                    invoice?.status === "10" && "bg-gray-100 text-gray-800",
                    invoice?.status === "20" && "bg-green-100 text-green-800",
                  )}>
                    {invoice?.status === "10" && "Unpaid"}
                    {invoice?.status === "20" && "Paid"}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-3">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Invoice Information
                    </h2>
                    {/* <p className="mt-1 max-w-2xl text-sm text-gray-500">Personal details and application.</p> */}
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1 cursor-pointer" onClick={()=>navigate(`/admin/orders/${invoice?.order}`)}>
                        <dt className="text-sm font-medium text-gray-500">Order</dt>
                        <dd className="mt-1 text-sm text-gray-900 hover:text-green-500">{invoice?.order}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Total</dt>
                        <dd className="mt-1 text-sm text-gray-900">&#8369; {total}</dd>
                      </div>
                      <div className="sm:col-span-1 cursor-pointer" onClick={()=>navigate(`/admin/customers/${invoice?.order_detail.customer_detail.id}`)}>
                        <dt className="text-sm font-medium text-gray-500">Customer</dt>
                        <dd className="mt-1 text-sm text-gray-900 hover:text-green-500">{invoice?.order_detail.customer_detail.email}</dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Notes</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {invoice?.notes}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <div
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg cursor-pointer"
                      onClick={()=>navigate("edit")}
                    >
                      Edit
                    </div>
                  </div>
                </div>
              </section>

            </div>
          </div>
        </main>

    </div>
  )
}

export default observer(InvoiceDetail)