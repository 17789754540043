import { useEffect, useState } from "react"
import { useForm } from 'react-hook-form'
import { Switch } from '@headlessui/react'
import { getUserList } from "api/users"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PayoutForm = ({ onSubmit, initialValues }) => {

  const [loading, setLoading] = useState(true)

  const { register, handleSubmit, reset, setValue } = useForm({
    defaultValues: initialValues
  })

  const [userList, setUserList] = useState([])

  const onFormSubmit = handleSubmit((data)=>{
    onSubmit(data)
  })

  useEffect(()=>{
    reset(initialValues)
  }, [initialValues, reset])

  useEffect(()=>{
    getUserList()
    .then(res=>{
      setUserList(res.data.results)
      setLoading(false)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
      setLoading(false)
    })
  }, [])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={onFormSubmit}>
      <div className="space-y-8 divide-y divide-gray-200">

        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Personal Information</h3>
            <p className="mt-1 text-sm text-gray-500">Please fill up as much detail as possible.</p>
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                User
              </label>
              <div className="mt-1">
              <select
                {...register('user', { required: true })}
                id="user"
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue=""
                onChange={e=>{
                  console.log(e.target.value)
                  const user = userList.find(usr => usr.id === parseInt(e.target.value))
                  setValue('amount', user.payout)
                }}
              >
                {/* <option value=""> -- No option -- </option>
                <option value={"1"}>United States</option>
                <option value={"2"}>Canada</option>
                <option value={"3"}>Mexico</option> */}
                <option disabled hidden value="">Select a user</option>
                {userList.map(item => (
                  <option key={item.id} value={item.id}>{item.email}</option>
                ))}
              </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Amount
              </label>
              <div className="mt-1">
                <input
                  {...register('amount')}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  defaultValue="0"
                  type="number"
                  min="0.01"
                  step="0.01"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Method
              </label>
              <div className="mt-1">
              <select
                {...register('method', { required: true })}
                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                defaultValue=""
              >
                {/* <option disabled hidden value="">Select a method</option> */}
                <option value="1">GCash</option>
              </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Reference Number
              </label>
              <div className="mt-1">
                <input
                  {...register('reference_number')}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  defaultValue="0"
                  type="text"
                />
              </div>
            </div>

          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default PayoutForm