export const sellers = [
  {
    id: 1,
    name: "John Doe",
    phone: "",
    mobile: "0987654321"
  },
  {
    id: 2,
    name: "Jane Doe",
    phone: "45746354",
    mobile: "0987654321"
  },
  {
    id: 3,
    name: "John Doe",
    phone: "5675685",
    mobile: ""
  },
  {
    id: 4,
    name: "John Doe",
    phone: "",
    mobile: "0987654321"
  },
  {
    id: 5,
    name: "John Doe",
    phone: "",
    mobile: "0987654321"
  },
  {
    id: 6,
    name: "Jane Doe",
    phone: "45746354",
    mobile: "0987654321"
  },
  {
    id: 7,
    name: "John Doe",
    phone: "5675685",
    mobile: ""
  },
  {
    id: 8,
    name: "John Doe",
    phone: "",
    mobile: "0987654321"
  },
]

export const products = [
  {
    id: 0,
    name: "Product Name",
    imageSrc: "/logo192.png",
    imageAlt: "tofu-1",
    color: "White",
    price: "123"
  },
  {
    id: 1,
    name: "Product Name",
    imageSrc: "/logo192.png",
    imageAlt: "tofu-1",
    color: "White",
    price: "123"
  },
  {
    id: 2,
    name: "Product Name",
    imageSrc: "/logo192.png",
    imageAlt: "tofu-1",
    color: "White",
    price: "123"
  },
  {
    id: 3,
    name: "Product Name",
    imageSrc: "/logo192.png",
    imageAlt: "tofu-1",
    color: "White",
    price: "123"
  },
]

export const reviews = [
  {
    id: 0,
    name: "John Doe",
    date: "July 20, 2021",
    rating: 5,
    review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada pellentesque elit eget gravida cum. Dignissim diam quis enim lobortis scelerisque fermentum dui."
  },
  {
    id: 1,
    name: "John Doe",
    date: "July 20, 2021",
    rating: 4,
    review: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Malesuada pellentesque elit eget gravida cum. Dignissim diam quis enim lobortis scelerisque fermentum dui."
  },
  
]