import { PrivateAPI } from "api"

export const getAllOrders = () => {
	return PrivateAPI.get("api/orders/")
}

export const getOrder = (id) => {
	return PrivateAPI.get(`api/orders/${id}/`)
}

export const createOrder = (data) => {
  return PrivateAPI.post(`api/orders/`, data)
}

export const updateOrder = (id, data) => {
  return PrivateAPI.put(`api/orders/${id}/`, data)
}