import { useNavigate, useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import ItemForm from "./ItemForm"
import { getItem, updateItem } from "api/items"

const ItemEdit = () => {

  const navigate = useNavigate()

  const { id } = useParams()
  const [item, setItem] = useState()

  useEffect(()=>{
    getItem(id)
    .then(res=>{
      setItem(res.data)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }, [id])

  const onSubmit = data => {
    updateItem(id, data)
    .then(res=>{
      navigate(`/admin/items/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <ItemForm onSubmit={onSubmit} initialValues={item}/>
    </div>
  )
}

export default ItemEdit