import { createPayout } from "api/payouts"
import { useNavigate } from "react-router-dom"
import PayoutForm from "./PayoutForm"

const PayoutAdd = () => {

  const navigate = useNavigate()

  const onSubmit = data => {
    createPayout(data)
    .then(res=>{
      navigate(`/admin/payouts/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <PayoutForm onSubmit={onSubmit}/>
    </div>
  )
}

export default PayoutAdd