import { createItem } from "api/items"
import { useNavigate } from "react-router-dom"
import ItemForm from "./ItemForm"

const ItemAdd = () => {

  const navigate = useNavigate()

  const onSubmit = data => {
    createItem(data)
    .then(res=>{
      navigate(`/admin/items/${res.data.id}`)
    })
    .catch(res=>{
      console.log("[ERROR]", res.response)
    })
  }

  return (
    <div>
      <ItemForm onSubmit={onSubmit}/>
    </div>
  )
}

export default ItemAdd