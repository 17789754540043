import { Routes, Route } from 'react-router-dom'

import { useAuthContext } from "store/auth"

import AdminRoute from 'utils/AdminRoute'
import SellerRoute from 'utils/SellerRoute'

import Home from "views/home"
import Login from 'views/login'

import Sellers from "views/sellers"

import Admin from 'views/admin'
import AdminDashboard from 'views/admin/components/AdminDashboard'

import AdminCustomers from 'views/admin/components/customers'
import CustomerAdd from 'views/admin/components/customers/components/CustomerAdd'
import CustomerList from 'views/admin/components/customers/components/CustomerList'
import CustomerDetail from 'views/admin/components/customers/components/CustomerDetail'
import CustomerEdit from 'views/admin/components/customers/components/CustomerEdit'

import AdminItems from "views/admin/components/items"
import ItemAdd from 'views/admin/components/items/components/ItemAdd'
import ItemList from 'views/admin/components/items/components/ItemList'
import ItemDetail from 'views/admin/components/items/components/ItemDetail'
import ItemEdit from 'views/admin/components/items/components/ItemEdit'

import AdminOrders from 'views/admin/components/orders'
import OrderList from 'views/admin/components/orders/components/OrderList'
import OrderDetail from 'views/admin/components/orders/components/OrderDetail'
import OrderAdd from 'views/admin/components/orders/components/OrderAdd'
import OrderEdit from 'views/admin/components/orders/components/OrderEdit'

import AdminInvoices from 'views/admin/components/invoices'
import InvoiceList from 'views/admin/components/invoices/components/InvoiceList'
import InvoiceDetail from 'views/admin/components/invoices/components/InvoiceDetail'
import InvoiceEdit from 'views/admin/components/invoices/components/InvoiceEdit'

import AdminPayouts from 'views/admin/components/payouts'
import PayoutList from 'views/admin/components/payouts/components/PayoutList'
import PayoutAdd from 'views/admin/components/payouts/components/PayoutAdd'
import PayoutDetail from 'views/admin/components/payouts/components/PayoutDetail'
import PayoutEdit from 'views/admin/components/payouts/components/PayoutEdit'

import AdminUsers from 'views/admin/components/users'
import UserDetail from 'views/admin/components/users/components/UserDetail'
import UserAdd from 'views/admin/components/users/components/UserAdd'
import UserEdit from 'views/admin/components/users/components/UserEdit'

import Application from "views/app"

import AppDashboard from 'views/app/components/dashboard'

import AppOrder from "views/app/components/orders"
import AppOrderList from "views/app/components/orders/components/OrderList"
import AppOrderDetail from "views/app/components/orders/components/OrderDetail"
import AppOrderAdd from "views/app/components/orders/components/OrderAdd"
import AppOrderEdit from "views/app/components/orders/components/OrderEdit"

import AppInvoices from "views/app/components/invoices"
import AppInvoiceList from 'views/app/components/invoices/components/InvoiceList'
import AppInvoiceDetail from "views/app/components/invoices/components/InvoiceDetail"

import AppPayouts from 'views/app/components/payouts'
import AppPayoutList from 'views/app/components/payouts/components/PayoutList'
import AppPayoutDetail from 'views/app/components/payouts/components/PayoutDetail'

import Footer from 'components/footer'
import { useState, useEffect} from 'react'
import { verify } from 'api/auth'
import UserList from 'views/admin/components/users/components/UserList'

const App = () => {

  const [loading, setLoading] = useState(false)

  const authStore = useAuthContext()

  useEffect(()=>{
    setLoading(true)
    const access = localStorage.getItem('access')

    if (access && !authStore.isLogged) {
      verify(access)
      .then(res=>{
        authStore.logUserIn(res.data.user)
        setLoading(false)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
        authStore.logUserOut()
        setLoading(false)
      })
    } else {
      setLoading(false)
    }

  }, [authStore])

  if (loading) {
    return <div>Loading...</div>
  }

  return (
    <div className='min-h-full flex flex-col'>
      <div className='flex-1 flex bg-gray-50'>
        <div className='w-full'>
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="sellers" element={<Sellers />} exact />

            <Route path="admin" element={<AdminRoute><Admin /></AdminRoute>} exact>
              <Route path="" element={<AdminDashboard />} exact/>

              <Route path="customers" element={<AdminCustomers />}>
                <Route path="" element={<CustomerList />} exact />  
                <Route path="add" element={<CustomerAdd />} exact />
                <Route path=":id" element={<CustomerDetail />} exact />
                <Route path=":id/edit" element={<CustomerEdit />} exact />
              </Route>

              <Route path="items" element={<AdminItems />} exact>
                <Route path="" element={<ItemList />} exact />
                <Route path="add" element={<ItemAdd />} exact />
                <Route path=":id" element={<ItemDetail />} exact />
                <Route path=":id/edit" element={<ItemEdit />} exact />
              </Route>

              <Route path="orders" element={<AdminOrders />} exact>
                <Route path="" element={<OrderList />} exact />
                <Route path="add" element={<OrderAdd />} exact />
                <Route path=":id" element={<OrderDetail />} exact />
                <Route path=":id/edit" element={<OrderEdit />} exact />
              </Route>

              <Route path="invoices" element={<AdminInvoices />} exact>
                <Route path="" element={<InvoiceList />} exact />
                <Route path=":id" element={<InvoiceDetail />} exact />
                <Route path=":id/edit" element={<InvoiceEdit />} exact />
              </Route>

              <Route path="payouts" element={<AdminPayouts />} exact>
                <Route path="" element={<PayoutList />} exact />
                <Route path="add" element={<PayoutAdd />} exact />
                <Route path=":id" element={<PayoutDetail />} exact />
                <Route path=":id/edit" element={<PayoutEdit />} exact />
              </Route>

              <Route path="users" element={<AdminUsers />} exact>
                <Route path="" element={<UserList />} exact />
                <Route path="add" element={<UserAdd />} exact />
                <Route path=":id" element={<UserDetail />} exact />
                <Route path=":id/edit" element={<UserEdit />} exact />
              </Route>
            </Route>

            <Route path="app" element={<SellerRoute><Application /></SellerRoute>} exact>
              <Route path="" element={<AppDashboard />} exact>
                
              </Route>

              <Route path="orders" element={<AppOrder />} exact>
                <Route path="" element={<AppOrderList />} exact />
                <Route path="add" element={<AppOrderAdd />} exact />
                <Route path=":id" element={<AppOrderDetail />} exact />
                <Route path=":id/edit" element={<AppOrderEdit />} exact />
              </Route>

              <Route path="invoices" element={<AppInvoices />} exact>
                <Route path="" element={<AppInvoiceList />} exact />
                <Route path=":id" element={<AppInvoiceDetail />} exact />
              </Route>

              <Route path="payouts" element={<AppPayouts />} exact>
                <Route path="" element={<AppPayoutList />} exact />
                <Route path=":id" element={<AppPayoutDetail />} exact />
              </Route>
            </Route>

            <Route path="login" element={<Login />} exact />

          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
