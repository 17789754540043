import { PrivateAPI } from "api"

export const getAllItems = (token) => {
	return PrivateAPI.get("api/items/", { token })
}

export const getItem = (id) => {
	return PrivateAPI.get(`api/items/${id}/`)
}

export const createItem = (data) => {
  return PrivateAPI.post(`api/items/`, data)
}

export const updateItem = (id, data) => {
  return PrivateAPI.put(`api/items/${id}/`, data)
}

export const getItemsByStatus = (params) => {
	return PrivateAPI.get(`api/items/status/`, params)
}