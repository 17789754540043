import { useEffect, useState } from "react"
import { useFieldArray, useForm, useWatch } from 'react-hook-form'
import { observer } from "mobx-react-lite"
import { useAuthContext } from "store/auth"
import { TiDelete } from 'react-icons/ti';
import { getItemsByStatus } from "api/items";
import { getAllCustomers, getAllCustomersForOrderForm } from "api/customers";


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const OrderForm = ({ onSubmit, initialValues }) => {

  const [loading, setLoading] = useState(true)
  const authStore = useAuthContext()

  const { register, handleSubmit, reset, control, setValue, watch } = useForm({
    defaultValues: initialValues ? initialValues : {
      items: [{
        line_number: 0,
        quantity: 0,
        sell_price: 0
      }]
    }
  })

  const { fields: orderItems, append, remove } = useFieldArray({
    control,
    name: "items",
    keyName: "uid"
  })

  const status = useWatch({
    control,
    name: 'status',
    defaultValue: "10"
  })

  const [items, setItems] = useState([])
  const [customers, setCustomers] = useState([])

  const [isActive, setIsActive] = useState(true)

  const onFormSubmit = handleSubmit((data)=>{
    onSubmit(data)
  })

  useEffect(()=>{
    reset(initialValues)
    console.log(initialValues)
  }, [initialValues, reset])

  useEffect(()=>{
    if (authStore.isLogged) {
      getItemsByStatus({ is_active: true })
      .then(res=>{
        setItems(res.data)
        setLoading(false)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
        setLoading(false)
      })
      getAllCustomersForOrderForm()
      .then(res=>{
        setCustomers(res.data)
        setLoading(false)
      })
      .catch(res=>{
        console.log("[ERROR]", res.response)
      })
    }

  }, [authStore.isLogged])

  // if (loading) {
  //   return <div>Loading...</div>
  // }

  return (
    <form className="space-y-8 divide-y divide-gray-200" onSubmit={onFormSubmit}>
      <div className="space-y-8 divide-y divide-gray-200">

        <div>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Invoice Information</h3>
            {/* <p className="mt-1 text-sm text-gray-500">Please fill up as much detail as possible.</p> */}
          </div>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Status
              </label>
              <div className="mt-1">
                <select
                  {...register('status')}
                  className={classNames(
                    "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                    status === "10" && "text-gray-600",
                    status === "20" && "text-yellow-600",
                    status === "30" && "text-blue-600",
                    status === "40" && "text-indigo-600",
                    status === "50" && "text-green-600",
                    status === "99" && "text-red-600",
                  )}
                  defaultValue=""
                >
                  <option value={"10"} className="text-black">Unpaid</option>
                  <option value={"20"} className="text-black">Paid</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                Customer
              </label>
              <div className="mt-1">
                <select
                  {...register('customer')}
                  className={classNames(
                    "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  )}
                  defaultValue={initialValues?.order}
                  disabled
                >
                  <option value={initialValues?.order}>{initialValues?.order_detail.customer_detail.email}</option>
                </select>
              </div>
            </div>

            <div className="sm:col-span-6">
              <label htmlFor="about" className="block text-sm font-medium text-gray-700">
                Notes
              </label>
              <div className="mt-1">
                <textarea
                  {...register('notes')}
                  rows={3}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
                  defaultValue={''}
                />
              </div>
            </div>

          </div>
        </div>

        <div className='pt-8'>
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">Items</h3>
          </div>

          <div className="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-6">
              {initialValues?.order_detail.items.map((item, idx) => {

                const total = watch(`order_detail.items[${idx}].quantity`) * watch(`order_detail.items[${idx}].sell_price`)
                const newTotal = (Math.round(total * 100) / 100).toFixed(2)

                return (
                  <div key={item.uid} className="mt-3 flex items-start sm:items-center">
                    <div className="w-6 sm:pt-0 pt-3">{idx+1}</div>
                    <div className="flex-1 grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-6">
                      <div className="col-span-4 sm:col-span-2">
                        <select
                          {...register(`order_detail.items[${idx}].item`)}
                          className={classNames(
                            "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md",
                          )}
                          defaultValue=""
                          onChange={(e)=>{
                            const cus = customers.find(cus => cus.id === parseInt(watch(`customer`)))
                            if (cus) {
                              const test = cus.special_prices.find((itemx)=> itemx.id === parseInt(e.target.value))
                              if (test) {
                                setValue(`items[${idx}].sell_price`, test.sell_price)
                                setValue(`items[${idx}].list_price`, test.item_detail.list_price)
                              } else {
                                const tempItem = items.find((itemx)=> itemx.id === parseInt(e.target.value) )
                                setValue(`items[${idx}].sell_price`, tempItem.sell_price)
                                setValue(`items[${idx}].list_price`, tempItem.list_price)
                              }
                            }
                            else {
                              const tempItem = items.find((itemx)=> itemx.id === parseInt(e.target.value) )
                              setValue(`items[${idx}].sell_price`, tempItem.sell_price)
                              setValue(`items[${idx}].list_price`, tempItem.list_price)
                            }
                          }}
                          disabled
                        >
                          <option disabled value="" hidden className="text-gray-300">Select an item</option>
                          {items.map((item)=>(
                            <option key={item.id} value={item.id}>{item.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="items-center col-span-4 grid grid-cols-6 gap-y-1 gap-x-4">
                        <div className="col-span-2">
                          <label className="block sm:hidden text-sm font-medium text-gray-700">
                            Qty
                          </label>
                          <input
                            {...register(`order_detail.items[${idx}].quantity`)}
                            type="number"
                            min={0}
                            step={'0.01'}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1"
                            placeholder="Qty"
                            disabled
                          />
                        </div>
                        <div className="col-span-2">
                          <label className="block sm:hidden text-sm font-medium text-gray-700">
                            Price
                          </label>
                          <input
                            {...register(`order_detail.items[${idx}].sell_price`)}
                            type="number"
                            min={0}
                            step={'0.01'}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Price"
                            disabled
                          />
                        </div>
                        <div className="col-span-2">
                          <label className="block sm:hidden text-sm font-medium text-gray-700">
                            Total
                          </label>
                          <input
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Price"
                            disabled
                            type="text"
                            value={newTotal}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default observer(OrderForm)