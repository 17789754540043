import { observer } from "mobx-react-lite"
import { useState } from "react"
import { useEffect } from "react"
import { CalendarIcon, LocationMarkerIcon, UsersIcon } from '@heroicons/react/solid'
import { useNavigate } from "react-router-dom"
import { useAuthContext } from "store/auth"
import { getAllPayouts } from "api/payouts"

const getAccessToken = () => localStorage.getItem('access')

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const PayoutInline = ({ payout }) => {

  const navigate = useNavigate()

  return (
    <li className="block hover:bg-gray-50 cursor-pointer" onClick={()=>navigate(`${payout.id}`)}>
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="text-sm font-medium text-indigo-600 truncate">{payout?.id}</p>
          <div className="ml-2 flex-shrink-0 flex">
            <p className={classNames(
              "px-2 inline-flex text-xs leading-5 font-semibold rounded-full",
              "bg-green-100 text-green-800"
            )}>
              &#8369; {payout.amount}
            </p>
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">
              <UsersIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {payout.user_detail.email}
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <LocationMarkerIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
              {payout.method === "1" && "GCash"}
              {payout.method === "2" && "BPI"}
            </p>
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <CalendarIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" aria-hidden="true" />
            <div>{payout.reference_number}</div>
          </div>
        </div>
      </div>
    </li>
  )
}

const PayoutList = () => {

  const authStore = useAuthContext()

  const [payouts, setPayouts] = useState([])
  const [next, setNext] = useState()
  const [previous, setPrevious] = useState()

  useEffect(()=>{
    if (authStore.isLogged) {
      getAllPayouts()
      .then(res=>{
        setPayouts(res.data.results)
        setNext(res.data.next)
        setPrevious(res.data.previous)
      })
      .catch(res=>{
        console.log(res.response)
      })
    }
  }, [authStore.isLogged])

  const onPrev = () => {
		previous && fetch(previous, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setPayouts(res.results)
      setNext(res.next)
      setPrevious(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  const onNext = () => {
		next && fetch(next, { headers: {
			Authorization: `JWT ${getAccessToken()}`
		}})
		.then(res=>res.json())
		.then(res=>{
			setPayouts(res.results)
      setNext(res.next)
      setPrevious(res.previous)
		})
		.catch(res=>{
			console.log(res.response)
		})
	}

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {payouts.map((payout)=>(
          <PayoutInline key={payout.id} payout={payout} />
        ))}
      </ul>

      <nav
        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
        aria-label="Pagination"
      >
        {/* <div className="hidden sm:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">1</span> to <span className="font-medium">10</span> of{' '}
            <span className="font-medium">20</span> results
          </p>
        </div> */}
        <div className="flex-1 flex justify-between sm:justify-end">
          <div
            className={classNames(
              "relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white",
              previous ? "cursor-pointer hover:bg-gray-50" : "bg-gray-100",
            )}
            onClick={onPrev}
          >
            Previous
          </div>
          <div
            className={classNames(
              "ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white",
              next ? "cursor-pointer hover:bg-gray-50" : "bg-gray-100",
            )}
            onClick={onNext}
          >
            Next
          </div>
        </div>
      </nav>

    </div>
  )
}

export default observer(PayoutList)