import { useLocation, useNavigate } from "react-router-dom"

const tabs = [
  { name: 'List', path: '', current: false },
  // { name: 'Add', path: 'add', current: false },
  // { name: 'Team Members', path: '', current: true },
  // { name: 'Billing', path: '', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const InvoiceNav = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const view = location.pathname.split("/", 4)[3] ? location.pathname.split("/", 4)[3] : ""

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.path === view)?.name}
          onChange={(e)=>{
            if (e.target.value === "List") {
              navigate(`/admin/invoices`)
            } else {
              navigate(`/admin/invoices/add`)
            }
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab.name}
                onClick={()=>{
                  navigate(tab.path)
                }}
                className={classNames(
                  tab.path === view
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer'
                )}
                aria-current={tab.path === view ? 'page' : undefined}
              >
                {tab.name}
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default InvoiceNav