import { SiFacebook, SiInstagram, SiTwitter } from "react-icons/si";

export default function Example() {
  return (
    <div className="bg-white">
      <div className="relative sm:py-16">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="relative rounded-2xl px-6 py-10 bg-indigo-600 overflow-hidden shadow-xl sm:px-12 sm:py-20">
            <div aria-hidden="true" className="absolute inset-0 -mt-72 sm:-mt-32 md:mt-0">
              <svg
                className="absolute inset-0 h-full w-full"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 1463 360"
              >
                <path
                  className="text-indigo-500 text-opacity-40"
                  fill="currentColor"
                  d="M-82.673 72l1761.849 472.086-134.327 501.315-1761.85-472.086z"
                />
                <path
                  className="text-indigo-700 text-opacity-40"
                  fill="currentColor"
                  d="M-217.088 544.086L1544.761 72l134.327 501.316-1761.849 472.086z"
                />
              </svg>
            </div>
            <div className="relative">
              <div className="sm:text-center">
                <h2 className="text-3xl font-extrabold text-white tracking-tight sm:text-4xl">
                  Follow us on our socials!
                </h2>
                <p className="mt-6 mx-auto max-w-2xl text-lg text-indigo-200 flex justify-center gap-x-12">
                  <SiFacebook size={40} className="cursor-pointer hover:text-indigo-400"/>
                  <SiInstagram size={40} className="cursor-pointer hover:text-indigo-400"/>
                  <SiTwitter size={40} className="cursor-pointer hover:text-indigo-400"/>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}